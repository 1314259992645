






































































































































































































































































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { initTabDetailsSource } from "@/store/resource/invoice.resource";
import { FREIGHT } from "@enum/freight.enum";
import Vue from "vue";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { formatCurrency, formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment, { Moment } from "moment";
import { ContactData, IAddressDataList } from "@/models/interface-v2/contact.interface";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { contactServices } from "@/services-v2/contact.service";
import { ResponseAccountingCurrency } from "@/models/interface-v2/currency.interface";
import { PREFERENCE_KEY } from "@/models/constant/preference.constant";
import { accountingAccountService } from "@/services-v2/accounting-account.service";
import { accountingCurrencyConversionService } from "@/services-v2/accounting-currency-conversion.service";
import { deliveryOrderService } from "@/services-v2/delivery-order.service";
import { ResponseDeliveryOrderDetail } from "@/models/interface-v2/delivery-order.interface";
import { InvoiceDetailSource } from "@/store/interface/store-invoice.interface";
import { productService } from "@/services-v2/product.service";
import { ResponseDetailProduct } from "@/models/interface-v2/product.interface";
import { ResponseDetailInvoiceAR } from "@/models/interface-v2/invoice.interface";
import { AR_INVOICE_STATUS } from "@/models/enums/invoice.enum";
import { Mode } from "@/models/enums/global.enum";

const VIEW_ONLY: AR_INVOICE_STATUS[] = [
  AR_INVOICE_STATUS.PARTIAL_PAID,
  AR_INVOICE_STATUS.FULLY_PAID,
  AR_INVOICE_STATUS.CANCELLED,
  AR_INVOICE_STATUS.UNPAID,
  AR_INVOICE_STATUS.RETURNED,
];

export default Vue.extend({
  name: "InvoiceHeader",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true */"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectBillAddress: () => import(/*webpackPrefetch: true */"@/components/shared/select-bill-address/CSelectBillAddress.vue"),
    CSelectShippAddress: () => import(/*webpackPrefetch: true */"@/components/shared/select-shipping-address/CSelectShippingAddress.vue"),
    CSelectTaxCalculation: () => import(/*webpackPrefetch: true */"@/components/shared/select-tax-calculation/CSelectTaxCalculation.vue"),
    CSelectTermOfPayment: () => import(/*webpackPrefetch: true */"@/components/shared/select-term-of-payment/CSelectTermOfPayment.vue"),
    CSelectCurrency: () => import(/*webpackPrefetch: true */"@/components/shared/select-currency/CSelectCurrency.vue"),
    InvoiceSalesName: () => import(/*webpackPrefetch: true */"./InvoiceSelectSalesName.vue"),
    InvoiceFindDO: () => import(/*webpackPrefetch: true */"./InvoiceFindDO.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        branchId: undefined as string | undefined,
        customerId: undefined as string | undefined,
        billAddress: undefined as string | undefined,
        shipAddress: undefined as string | undefined,
        salesName: undefined as string | undefined,
        taxType: TAX_CALCULATION.NONE as TAX_CALCULATION | string | undefined,
        top: undefined as string | undefined,
        invoiceDate: null as string | null,
        accountingDate: null as string | null,
        currencyId: undefined as string | undefined,
        currency: "",
        currencyRate: undefined as number | undefined,
        receivableAccountId: "", // default value based on preferences
        invoiceDescription: "",
      },
      dtCustomerAddress: [] as IAddressDataList[],
      modal: {
        do: false,
      },
      currencyCode: "IDR",
      receivableAccount: "",
      loading: {
        generate: false,
      }
    };
  },
  computed: {
    ...mapState({
      storeForm: (st: any) => st.invoiceStore.form,
      appPreference: (st: any) => st.preferenceStore.appPreference,
      detailInvoice: (st: any) => st.invoiceStore.detailInvoice,
      tabDetailSource: (st: any) => st.invoiceStore.tabDetailSource,
    }),
    ...mapGetters({
      listSales: "invoiceStore/GET_LIST_SALES",
      getPreferenceValue: "preferenceStore/GET_PREFERENCE_VALUE",
      // getSumGrossAfterDiscount: "invoiceStore/GET_SUM_GROSS_AFTER_DISCOUNT",
    }),
    getFreightId(): string {
      return this.getPreferenceValue(PREFERENCE_KEY.FEATURE_FREIGHT)?.value || "";
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    },
    isIDR(): boolean {
      return this.currencyCode === "IDR";
    },
    isViewOnly(): boolean {
      return VIEW_ONLY.includes(this.detailInvoice.status);
    },
    getDetailInvoiceDONumber() {
      return this.detailInvoice.deliveryOrders.map(x => x.documentNumber);
    },
    disabled() {
      return {
        generate: this.storeForm.deliveryOrders.every(x => x.generated)
      };
    },
    isTaxNone(): boolean {
      return this.form.taxType === TAX_CALCULATION.NONE;
    },
    isTaxExclusive(): boolean {
      return this.form.taxType === TAX_CALCULATION.EXCLUSIVE;
    },
    isTaxInclusive(): boolean {
      return this.form.taxType === TAX_CALCULATION.INCLUSIVE;
    },
  },
  watch: {
    detailInvoice() {
      this.fillForm();
    }
  },
  methods: {
    formatCurrency,
    formatterNumber,
    reverseFormatNumber,
    moment,
    ...mapMutations({
      setForm: "invoiceStore/SET_FORM",
      setFormCurrencyCode: "invoiceStore/SET_FORM_CURRENCY_CODE",
      setTabDetailSource: "invoiceStore/SET_TAB_DETAIL_SOURCE",
    }),
    ...mapActions({
      setPrefillTabTaxDetailSource: "invoiceStore/ACT_FIND_CUSTOMER_TAX_DATA",
      calcProRatePricing: "invoiceStore/CALC_PRO_RATE_PRICING_V2",
    }),
    commitForm(): void {
      this.setForm({...this.storeForm, ...this.form});
    },
    onChangeDate(value: Moment): void {
      this.form.invoiceDate = value?.format() || "";
      this.form.accountingDate = value?.format() || "";
      this.setForm({
        ...this.storeForm,
        invoiceDate: value?.format() || "",
        accountingDate: value?.format() || ""
      });
    },
    onSelectCustomer({ meta }: {meta: ContactData}): void {
      this.dtCustomerAddress = meta.addressDataList || [];
      const billAddress = meta.addressDataList.find(x => x.billTo && x.primaryBillTo);
      const shipAddress = meta.addressDataList.find(x => x.shipTo && x.primaryShipTo);
      if (billAddress) {
        this.form.billAddress = `${billAddress.address}, ${billAddress.cityDistrict}, ${billAddress.country}, ${billAddress.postalCode}`;
      }
      if (shipAddress) {
        this.form.shipAddress = `${shipAddress.address}, ${shipAddress.cityDistrict}, ${shipAddress.country}, ${shipAddress.postalCode}`;
      }
      this.setForm({...this.storeForm, ...this.form, deliveryOrderIds: [], deliveryOrders: []});
      this.setTabDetailSource([]);
      this.findTOP(meta.id);
    },
    async findTOP(customerId: string): Promise<void> {
      try {
        const { top } = await contactServices.getContactData(customerId);
        this.form.top = top?.toString() || undefined;
        this.commitForm();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    showFinder(): void {
      this.modal.do = true;
    },
    disabledDateInvoice(now: Moment): boolean {
      const order = this.storeForm.deliveryOrders;
      const maxDate: string | null = order[0] ? order[0].deliveryOrderDate : null;
      if (maxDate) {
        return (now < this.moment(maxDate)) || now > this.moment();
      } else {
        return now > this.moment();
      }
    },
    onSelectCurrency({ meta }: {meta: ResponseAccountingCurrency}): void {
      this.currencyCode = meta.currencyCode;
      this.setFormCurrencyCode(this.currencyCode);
      this.commitForm();
      if (this.currencyCode !== "IDR") {
        this.findCurrencyRate();
      }

      /**
       * find account based on currency
       */
      this.findAccountReceivableByCurrency(this.currencyCode);
    },
    /**
     * find currency rate
     * fromCurrency taken from selected currency
     * toCurrency taken from preference
     */
    async findCurrencyRate(): Promise<void> {
      try {
        const basedCurrency = this.getPreferenceValue(PREFERENCE_KEY.FEATURE_BASED_CURRENCY);
        const search = `fromCurrency.currencyCode~${this.currencyCode}_AND_toCurrency.secureId~${basedCurrency.value}`;
        const { data } = await accountingCurrencyConversionService.conversionList({ search });
        this.form.currencyRate = data[0]?.rate || 0; 
        this.commitForm();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    async getDetailCOA(id: string): Promise<void> {
      try {
        const { code, description } = await accountingAccountService.getDetailCOA(id);
        this.receivableAccount = `${code} - ${description}`;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    /**
     * @param code currency code
     */
    async findAccountReceivableByCurrency(code: string): Promise<void> {
      try {
        const search = `parentAccount.code~130-10_AND_accountCurrency.currencyCode~${code}`;
        const { data } = await accountingAccountService.getListCOA({ search });
        this.receivableAccount = "";
        this.form.receivableAccountId = "";
        if (!data.length) return;
        this.receivableAccount = `${data[0].code} - ${data[0].description}`;
        this.form.receivableAccountId = data[0].id;
        this.commitForm();
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    findProductTax(productId: string): Promise<any> {
      return productService.detailProduct(productId);
    },
    /**
     * get detail from each delivery order
     * then assign as data source for
     * table on tab detail
     */
    async generateLines(): Promise<void> {
      try {
        this.loading.generate = true;

        /**
         * get all DO ids from store
         */
        let list: string[] = [];
        this.storeForm.deliveryOrders.forEach(x => {
          if (!x.generated) {
            list.push(x.id);
          }
        });
        if (!list.length) return;

        /**
         * hold all promises from get
         * detail DO service
         */
        const promises: Promise<ResponseDeliveryOrderDetail>[] = [];
        list.forEach(x => {
          promises.push(deliveryOrderService.getOutstandingInvoice(x));
        });
        const res = await Promise.all(promises);

        /**
         * map lines from DO into
         * data source tab detail table
         */
        let lines: InvoiceDetailSource[] = [];
        let descriptions = "";
        res.forEach(x => {
          x.deliveryOrderLines.forEach((y) => {

            descriptions += `${y.alias}\n`;
            
            lines.push({
              no: 0,
              documentReference: x.deliveryOrderNumber,
              productCode: y.productCode,
              productName: y.productName,
              productId: y.productId,
              brand: "", // missing from detail
              qty: y.qtyPick,
              qtyDO: y.qtyPick,
              uomId: y.uomId,
              uom: y.uomName,
              price: y.salePrice,
              revenueAccountId: "", // later get detail product
              revenueAccount: "", // later get detail product
              // baseAmount: baseAmount.toNumber(),
              baseAmount: 0,
              taxCode: this.isTaxNone ? "" : y.taxCode,
              taxId: this.isTaxNone ? "" : y.taxId,
              // taxAmount: taxAmount.toNumber(),
              taxAmount: 0,
              // subTotal: subTotal.toNumber(),
              subTotal: 0,
              description: "", // missing from detail
              alias: y.alias || "",
              id: null,
              batchNumber: y.batchNumber,
              batchNumberId: y.batchNumberId,
              incomeTaxId: "",

              // additional
              fromDO: true,
              key: new Date().valueOf(),
              incomeAccountTaxId: "",
              percentDiscount: y.percentDiscount || 0,
              discountValue: y.discountValue || 0,
              billingPeriodModal: "",
              salesOrderLineId: y.salesOrderLineId,
              deliveryOrderLineId: y.id, // DO line id
              taxValue: 0,
              location: y.locationId,
              taxRate: this.isTaxNone ? 0 : y.unitTaxRate,
              taxRatePercent: 0,
              taxRateValue: 0,
              baseAmountValue: 0,
              invoiceICBillingLineId: "",
              proRateRatio: 0,
              grossAfterDiscount: 0,
              proRateAdditionalDiscountAmount: 0,
              amountAfterProRateAdditionalDiscount: 0,
              proRateAmountAfterAdditionalDiscountRatio: 0,
              proRatePrepaymentRatio: 0,
              proRatePrepaymentAmount: 0,
            });
          });
        });

        this.form.invoiceDescription = descriptions;

        /**
         * check if DO have freight customer
         * generate line with product service FREIGHT
         */
        const hasFreightCustomer = res.find(x => x.freight === FREIGHT.CUSTOMER);
        const row = initTabDetailsSource();
        /**
         * get FREIGHT from preference
         * then get detail product FREIGHT
         */
        const { code, name, id, baseUnitId, baseUnit, salesAccountId, salesAccountName }: ResponseDetailProduct = await productService.detailProduct(this.getFreightId as string);
        row.productCode = code;
        row.productName = name;
        row.productId = id;

        row.uomId = baseUnitId;
        row.uom = baseUnit;

        // product service is excluded from DO
        // FORCE service product type
        row.fromDO = false;

        row.qtyDO = 0;
        row.qty = 1;

        row.revenueAccountId = salesAccountId;
        row.revenueAccount = salesAccountName;

        /**
         * get detail product
         * to get revenue account
         */
        const promises2: Promise<ResponseDetailProduct>[] = lines.map(x => productService.detailProduct(x.productId));
        const res2 = await Promise.all(promises2);
        res2.forEach(x => {
          lines.forEach(y => {
            if (y.productId === x.id) {
              y.revenueAccountId = x.salesAccountId;
              y.revenueAccount = x.salesAccountName;
            }
          });
        });

        /**
         * commit mutation into
         * data source tab detail
         */
        if (hasFreightCustomer) {
          const hasFreightRow = this.tabDetailSource.find(x => x.productId === row.productId);
          if (!hasFreightRow) {
            this.setTabDetailSource([...this.tabDetailSource, ...lines, row]);
          }
        } else {
          this.setTabDetailSource([...this.tabDetailSource, ...lines ]);
        }

        /**
         * set number index from each line
         */
        this.tabDetailSource.forEach((x, i) => x.no = i + 1);

        /**
         * init generated prop as flag to indicate
         * wether DO is ever generated before to
         * avoid data duplication every time DO
         * line is created
         */
        this.storeForm.deliveryOrders.forEach(x => {
          x.generated = true; // toggle flag
        });

        this.mapAddress(res[0]);

        // assign first option sales name
        this.form.salesName = this.listSales[0];

        this.commitForm();

        this.calcProRatePricing();

      } catch (error) {
        console.error(error);
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.generate = false;
      }
    },
    /**
     * map address from DO
     */
    mapAddress(deliveryOrder?: ResponseDeliveryOrderDetail): void { 
      if (!deliveryOrder) return;
      this.form.billAddress = deliveryOrder?.customerBillToAddress ?? "";
      this.form.shipAddress = deliveryOrder?.customerShipToAddress ?? "";
    },
    onSelectTaxType(): void {
      this.commitForm();
      this.resetTableCalc();
      if (this.isTaxExclusive || this.isTaxInclusive) {
        this.setPrefillTabTaxDetailSource();
      }
    },
    /**
     * set FORM field from detail invoice
     */
    fillForm(): void {
      const detail = this.detailInvoice as ResponseDetailInvoiceAR;
      this.form = {
        branchId: detail.branchWarehouseId,
        customerId: detail.customerId,
        billAddress: detail.customerBillToAddress,
        shipAddress: detail.customerShipToAddress,
        salesName: detail.salesName,
        taxType: detail.taxType,
        top: detail.termOfPayment.toString(),
        invoiceDate: detail.invoiceDate,
        accountingDate: detail.accountingDate,
        currencyId: detail.currencyId,
        currency: detail.currency,
        currencyRate: detail.currencyRate,
        receivableAccountId: detail.receivableAccountId, // default value based on preferences
        invoiceDescription: detail.description,
      };
    },
    resetTableCalc(): void {
      this.calcProRatePricing();
    },
  },
});
