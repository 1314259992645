var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !!_vm.detailInvoice.status
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { sm: 24, md: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_invoice_number") } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.documentNumber || "-"))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_journal_number") } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.journalNo || "-"))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { sm: 24, md: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.status || "-"))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8 } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_branch"), prop: "branchId" } },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.detailInvoice.branchWarehouseName || "-")
                        )
                      ])
                    : _c("CSelectBranch", {
                        on: { "on-change": _vm.commitForm },
                        model: {
                          value: _vm.form.branchId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "branchId", $$v)
                          },
                          expression: "form.branchId"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_customer_name"),
                    prop: "customerId"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.customerName || "-"))
                      ])
                    : _c("CSelectCustomer", {
                        attrs: {
                          "for-list": false,
                          "prop-customer-name": _vm.detailInvoice.customerName,
                          disabled: !!_vm.detailInvoice.status
                        },
                        on: {
                          "on-select": function(ref) {
                            var meta = ref.meta

                            return _vm.onSelectCustomer(meta)
                          }
                        },
                        model: {
                          value: _vm.form.customerId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customerId", $$v)
                          },
                          expression: "form.customerId"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_bill_address"),
                    prop: "billAddress"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.detailInvoice.customerBillToAddress || "-")
                        )
                      ])
                    : _c("CSelectBillAddress", {
                        attrs: { "prop-address": _vm.dtCustomerAddress },
                        on: { "on-select": _vm.commitForm },
                        model: {
                          value: _vm.form.billAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "billAddress", $$v)
                          },
                          expression: "form.billAddress"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_ship_address"),
                    prop: "shipAddress"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.detailInvoice.customerShipToAddress || "-")
                        )
                      ])
                    : _c("CSelectShippAddress", {
                        attrs: { "prop-address": _vm.dtCustomerAddress },
                        on: { "on-select": _vm.commitForm },
                        model: {
                          value: _vm.form.shipAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "shipAddress", $$v)
                          },
                          expression: "form.shipAddress"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_invoice_date"),
                    prop: "invoiceDate"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("date")(_vm.detailInvoice.invoiceDate))
                        )
                      ])
                    : _c("a-date-picker", {
                        staticClass: "w-100",
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          placeholder: _vm.$t("lbl_choose"),
                          "disabled-date": _vm.disabledDateInvoice
                        },
                        on: { change: _vm.onChangeDate },
                        model: {
                          value: _vm.form.invoiceDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "invoiceDate", $$v)
                          },
                          expression: "form.invoiceDate"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: { label: _vm.$t("lbl_currency"), prop: "currencyId" }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.currency || "-"))
                      ])
                    : _c("CSelectCurrency", {
                        attrs: { "prop-default": _vm.isModeCreate },
                        on: { "on-select": _vm.onSelectCurrency },
                        model: {
                          value: _vm.form.currencyId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "currencyId", $$v)
                          },
                          expression: "form.currencyId"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          !_vm.isIDR
            ? _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_rate"), prop: "currencyRate" }
                    },
                    [
                      _vm.isViewOnly && _vm.detailInvoice.currency !== "IDR"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.detailInvoice.currencyRate
                                )
                              )
                            )
                          ])
                        : _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: _vm.$t("lbl_type_here"),
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0
                            },
                            on: { change: _vm.commitForm },
                            model: {
                              value: _vm.form.currencyRate,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "currencyRate", _vm._n($$v))
                              },
                              expression: "form.currencyRate"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_receivable_account"),
                    prop: "receivableAccountId"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.detailInvoice.receivableAccount || "-")
                        )
                      ])
                    : _c("span", [_vm._v(_vm._s(_vm.receivableAccount || "-"))])
                ]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_accounting_date"),
                    prop: "accountingDate"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("date")(_vm.detailInvoice.accountingDate)
                          )
                        )
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm._f("date")(_vm.form.accountingDate)))
                      ])
                ]
              )
            ],
            1
          ),
          _vm.isViewOnly
            ? _c(
                "a-col",
                { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_delivery_order") } },
                    _vm._l(_vm.getDetailInvoiceDONumber, function(item) {
                      return _c("a-tag", { key: item }, [
                        _vm._v(" " + _vm._s(item || "-") + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            : _c(
                "a-col",
                { attrs: { sm: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_delivery_order") } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !!_vm.storeForm.deliveryOrders.length,
                              expression: "!!storeForm.deliveryOrders.length"
                            }
                          ]
                        },
                        _vm._l(_vm.storeForm.deliveryOrders, function(item) {
                          return _c("a-tag", { key: item.id }, [
                            _vm._v(
                              " " +
                                _vm._s(item.deliveryOrderNumber || "-") +
                                " "
                            )
                          ])
                        }),
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !!_vm.storeForm.deliveryOrders.length,
                              expression: "!!storeForm.deliveryOrders.length"
                            }
                          ],
                          attrs: {
                            block: "",
                            type: "primary",
                            icon: "setting",
                            disabled: _vm.disabled.generate,
                            loading: _vm.loading.generate
                          },
                          on: { click: _vm.generateLines }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            block: "",
                            icon: "search",
                            disabled:
                              !_vm.form.customerId || !_vm.form.currencyId
                          },
                          on: { click: _vm.showFinder }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_sales_person_name"),
                    prop: "salesName"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.salesName || "-"))
                      ])
                    : _c("InvoiceSalesName", {
                        on: { "on-select": _vm.commitForm },
                        model: {
                          value: _vm.form.salesName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "salesName", $$v)
                          },
                          expression: "form.salesName"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t("lbl_tax_type"), prop: "taxType" } },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.taxType || "-"))
                      ])
                    : _c("CSelectTaxCalculation", {
                        on: { "on-select": _vm.onSelectTaxType },
                        model: {
                          value: _vm.form.taxType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "taxType", $$v)
                          },
                          expression: "form.taxType"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8, xl: 6 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: { label: _vm.$t("lbl_term_of_payment"), prop: "top" }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.detailInvoice.termOfPayment === 0 ||
                              _vm.detailInvoice.termOfPayment > 0
                              ? _vm.detailInvoice.termOfPayment
                              : "-"
                          )
                        )
                      ])
                    : _c("CSelectTermOfPayment", {
                        on: { "on-select": _vm.commitForm },
                        model: {
                          value: _vm.form.top,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "top", $$v)
                          },
                          expression: "form.top"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, lg: 8 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t("lbl_invoice_description"),
                    prop: "invoiceDescription"
                  }
                },
                [
                  _vm.isViewOnly
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.detailInvoice.description || "-"))
                      ])
                    : _c("a-textarea", {
                        attrs: {
                          placeholder: _vm.$t("lbl_type_here"),
                          "auto-size": { minRows: 1, maxRows: 4 }
                        },
                        on: { change: _vm.commitForm },
                        model: {
                          value: _vm.form.invoiceDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "invoiceDescription", $$v)
                          },
                          expression: "form.invoiceDescription"
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.modal.do
        ? _c("InvoiceFindDO", {
            model: {
              value: _vm.modal.do,
              callback: function($$v) {
                _vm.$set(_vm.modal, "do", $$v)
              },
              expression: "modal.do"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }